import React from 'react'
import Link from 'next/link'
// import { useSocialLogin } from 'hooks'
import { useRouter } from 'next/router'
import { WContainer } from '@components/atoms'
import { FormLogin, Page } from 'components/organisms'
import styles from './LoginTemplate.module.sass'

const LoginTemplate = ({ pagina, iframe, from }) => {
  // const { FacebookLoginButton } = useSocialLogin(from, iframe)

  const router = useRouter()

  const nextPage = router.query?.next

  const activated = router.query?.activated
  const email = router.query?.email
  let description = ''
  if (email) {
    description = 'Questa email risulta essere già registrata. Accedi con le tue credenziali.'
  } else if (activated) {
    description =
      '<p>Account verificato con successo</p><p>Verrai reindirizzato sul tuo profilo.</p>'
  } else {
    description = pagina.descrizione
  }

  return (
    <Page pagina={pagina} item={pagina} contentClassName={styles.loginTemplate} iframe={iframe}>
      <WContainer variant="xxs">
        {(!!pagina.titolo || !!pagina.descrizione) && (
          <div className={styles.head}>
            {!!pagina.titolo && <h1 className={styles.title}>{pagina.titolo}</h1>}
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        )}
        <FormLogin nextPage={nextPage} iframe={iframe} from={from} email={email} />
        <p className={styles.register}>
          Non hai un Account?{' '}
          <Link
            legacyBehavior
            href={
              iframe && from
                ? `/registrazione-parto-riparto?f=${encodeURIComponent(from)}`
                : iframe && !from
                ? '/registrazione-parto-riparto?f=patronato-acli'
                : '/registrazione'
            }
          >
            <a>Registrati</a>
          </Link>
        </p>
        {/* <FacebookLoginButton /> */}
      </WContainer>
    </Page>
  )
}

export default LoginTemplate
